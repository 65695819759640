import React from "react";
import ReactDOM from "react-dom/client";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './styles/index.css';
// import App from './App';
// import { PublicClientApplication } from '@azure/msal-browser';
// import { MsalProvider } from '@azure/msal-react';
// import { msalConfig } from './authConfig';

// /**
//  * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
//  * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
//  */
// const msalInstance = new PublicClientApplication(msalConfig);

// const root = ReactDOM.createRoot(document.getElementById('root'));

// /**
//  * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
//  */
// root.render(
//     <React.StrictMode>
//         <MsalProvider instance={msalInstance}>
//             <App />
//         </MsalProvider>
//     </React.StrictMode>
// );

import { PublicClientApplication, EventType } from "@azure/msal-browser";
// import { BrowserRouter } from "react-router-dom";

import { msalConfig } from "./authConfig.js";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.css";

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App instance={msalInstance} />);
